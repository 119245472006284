/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, ScrollMagic, Swiper, Scrollbar) => {

        //----  MENU  -----//
        $('.menu a').each(function(){
            var url = window.location.href;
            if( $(this).attr('href') == url ){
                $(this).addClass('active-page');
            }
        })


        //----  ANIMATION HEADER  -----//

        const $menuProductsBtn = $('header').find('.btn-menu');
        const $menuProductsBtnBars = $menuProductsBtn.find('> span');

        var controller = new ScrollMagic.Controller();

        var scrollHeader = new ScrollMagic.Scene({
             offset: $('.header').offset().top
        })
        .setPin("#sticker") 
        .setClassToggle("#sticker", "pinned")
        .addTo(controller);

        var isMenuCLicked = false;

        //timeline menu
        var timelinemenu = new TimelineMax({ paused: true });
        timelinemenu.to($menuProductsBtnBars.eq(0), .3,
            { x: 0, y: 7, rotation: 45, ease: Power1.easeOut }, 'start=0');

        timelinemenu.to($menuProductsBtnBars.eq(1), .3,
            { autoAlpha: 0, ease: Power1.easeOut }, 'start=0');

        timelinemenu.to($menuProductsBtnBars.eq(2), .3,
            { x: 0, y: -7, rotation: -45, ease: Power1.easeOut }, 'start=0');

        timelinemenu.fromTo($('.menu-fixed'), .6,
            { height: '0' },
            { height: '100vh', ease: Power4.easeInOut});

        timelinemenu.staggerFromTo($('.menu-fixed').find('li'), 1.5,
            { y: '40%', opacity: 0 },
            { y: '0%', opacity: 1, ease: Power4.easeInOut }, .15, '-=0.6');
        

        $('.btn-menu').on("click", function () {
            if (!isMenuCLicked) {
                timelinemenu.play().timeScale(1);
                $('.burger span').addClass("opened");
                isMenuCLicked = true;
            } else {
                timelinemenu.reverse().timeScale(2);
                $('.burger span').removeClass("opened");
                isMenuCLicked = false;
            }
        });


        //----  ANIMATION DES NOMBRES  -----//


        if ($('#numbers').length > 0) {
            var scrollNumbers = new ScrollMagic.Scene({
                triggerElement: "#numbers",
                triggerHook: 'onEnter'
            })
                .on("enter", function () {
                    $('.numbers').find('.number').each(function () {
                        var nbr = $(this);
                        $({
                            Counter: 0
                        }).animate({
                            Counter: nbr.text()
                        }, {
                                duration: 2000,
                                easing: 'swing',
                                step: function () {
                                    nbr.text(Math.ceil(this.Counter));
                                }
                            });
                    })
                })
                .addTo(controller);    
        }

        //----  SLIDER TEAM  -----//

        const scrollAreas       = document.querySelectorAll('.scroll-area');
        const $scrollAreas      = $('.scroll-area');

        /*
        |
        | Scroll Areas
        |---------------
        */
        Array.prototype.forEach.call(scrollAreas, element => {
            Scrollbar.init(element);
        });

        $('.associated-slider .presentation').mouseover(function(){
            $('body').css('overflow','hidden');
        })
        .mouseout(function(){
            $('body').css('overflow','inherit');
        })

        

        if( $('body').hasClass('page-template-about')){

            var imageSwiper = new Swiper('.swiper-image', {
                slidesPerView: 6,
                spaceBetween: 30,
                watchSlidesVisibility: true,
                watchSlidesProgress: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                breakpoints: {
                    1200:{
                        slidesPerView: 4,
                        spaceBetween: 30
                    },
                    992: {
                        slidesPerView: 3,
                        spaceBetween: 10
                    },
                    768:{
                        slidesPerView: 2,
                        spaceBetween: 30
                    },
                    481: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                    }
                }

            });

            var imgSwiperMobile = new Swiper('.swiper-image-mobile',{
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            });

            var txtSwiper = new Swiper('.swiper-text-container',{
                slidesPerView: 1,
                spaceBetween: 30,
                thumbs: {
                    swiper: imageSwiper
                },

            });

            if( $(window).width() < 577 ){
                txtSwiper.destroy();
                txtSwiper = new Swiper('.swiper-text-container',{
                    slidesPerView: 1,
                    spaceBetween: 30,
                })
            }

            imgSwiperMobile.controller.control = txtSwiper;
            txtSwiper.controller.control = imgSwiperMobile;
        }

        
    


        //----  SLIDER PAGE ACTU  -----//


        var swiperActu = new Swiper('.swiper-actu', {
            slidesPerView: 3,
            spaceBetween: 30,
            loop: false,
            breakpoints: {
                992: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                },
                620: {
                    slidesPerView: 1,
                    spaceBetween: 30,
                }
            }

        });

        //----  SLIDER PAGE NOUS REJOINDRE  -----//

        var swiperTestimony = new Swiper('.testimony-swiper .swiper-container', {
            slidesPerView: 1,
            navigation: {
                nextEl: '.testimony-button-next',
                prevEl: '.testimony-button-prev',
            },
        });


        //----  CURSOR  -----//

        

        $('.swiper-image').on('mousemove', function(e){
            var nbSlides = $(".swiper-image .swiper-slide").length;
            if(nbSlides > 6){
                var posx = e.clientX;
                var posy = e.clientY;
                TweenLite.to($('.slider-cursor'),1.2, {autoAlpha:1, x:posx, y:posy, ease: Elastic.easeOut});
            }
        })
        $('.swiper-image').on('mouseout', function (e) {
            TweenLite.to($('.slider-cursor'), .2, { autoAlpha: 0});
        })


        //----  TWO BLOCKS  -----//


        $('.inner').on('mouseover', function(){
            $(this).find('.arrow').addClass('arrow-round-orange');
        })
        $('.inner').on('mouseout', function () {
            $(this).find('.arrow').removeClass('arrow-round-orange');
        })


        //----  FILTER  -----//
        function filterResponsive(){
            var windowSize = $(window).width()
            if( windowSize <= 575 ){
                $('#filter').addClass('filter-list-responsive').removeClass('filter-list');
            }else if(windowSize > 575) {
                $('#filter').addClass('filter-list').removeClass('filter-list-responsive');
            }
        }
        
        $(window).on('load', function(){
            filterResponsive();
        })
        $(window).on('resize', function(){
            filterResponsive();
        })


        var isfiltering = false;

        var timelinefilter = new TimelineMax({paused: true});
        timelinefilter.fromTo($('.filter-list'), .3, {width: "68px",padding: "0"}, {width: "100%",padding: "0 20px 0 80px"});
        timelinefilter.staggerFromTo($('.filter-list ul'), .3, {display: "none", autoAlpha: 0}, {display: "flex", autoAlpha: 1});

        $('.filter svg').on('click', function(){
            if (isfiltering == false) {
                timelinefilter.play();
                isfiltering = true;
            } else {
                timelinefilter.reverse();
                isfiltering = false;
            }
        })


        //----  TRIGGER CLICK CONTACT FILE  -----//


        $('#upload_file').on('click', function(){
            $('.upload_file_hide input').click();
        })
        $('input[type="file"]').change(function(e){
            var fileName = e.target.files[0].name.substring(0, 20);
            var finalName = fileName+"..."
            $('#upload_file span').html(finalName);
            
        });


        // ---- ANCHOR SMOOTH SCROLL -- //

        var headerHeight = $('header').height();

        $("a[href*='#']:not([href='#'])").click(function() {
            if (
                location.hostname == this.hostname
                && this.pathname.replace(/^\//,"") == location.pathname.replace(/^\//,"")
            ) {
                var anchor = $(this.hash);
                anchor = anchor.length ? anchor : $("[name=" + this.hash.slice(1) +"]");
                if ( anchor.length ) {
                    $("html, body").animate( { scrollTop: anchor.offset().top-headerHeight }, 1500);
                }
            }
        });

        //----  JOIN US  -----//

        $('.postulate-link').on('click', function(){
            const title = $(this).data('popup');

            $('.pum-container').find('.popmake-title').append(' ' + title);
            $('.pum-container').find('#input_2_9').val(title);

        })

	}
}

