/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs & dependancies
|--------------------------------
*/
import Swiper from 'swiper/dist/js/swiper.min';
require('../lib/iziModal/js/iziModal.js')($); //désolé

/*
|
| Importing Animations libs
|----------------------------
*/
import { TweenMax } from "gsap/TweenMax";
import TimelineMax from "gsap/TimelineMax";
import SplitText from "../lib/gsap-pro/SplitText";
import ScrollTo from "gsap/ScrollToPlugin";
import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap.js';
import 'scrollmagic/scrollmagic/uncompressed/plugins/jquery.ScrollMagic.js';
import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';
import ScrollMagic from 'scrollmagic';
import SubZero from '../lib/subzero/subzero.js';
import Scrollbar from 'smooth-scrollbar';

/*
|
| Importing Utils
|----------------------------
*/
import './utils/fa';
import './utils/jquery.sticky';
import Router from './utils/router.js';

/*
|
| Importing App files
|----------------------
*/
import * as app from './components/global.js';
import main 	from './pages/main.js';

/*
|
| Routing
|----------
*/
const routes = new Router([
	{
		'file': main, 
		'dependencies': [app, ScrollMagic, Swiper, Scrollbar]
	}

]);

/*
|
| Run
|------
*/
(($) => { routes.load() })(jQuery);

